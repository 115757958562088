import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  function clearMessages() {
    $w('#multiStateBox1').changeState('emptyState');
    $w('#multiStateBox2').changeState('empty');
  }

  function displayServerError(message: string) {
    $w('#multiStateBox2').changeState('serverError');
    $w('#text5').text = message;
  }

  function displayError(message: string) {
    $w('#multiStateBox1').changeState('invalidPhoneNumber');
    $w('#text4').text = message;
  }

  function displaySuccess(message: string) {
    $w('#multiStateBox2').changeState('success');

    $w('#text6').text = message;
  }
  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      clearMessages();
    },
    exports: {
      clearMessages,
      displayError,
      displayServerError,
      displaySuccess,
    },
  };
});
