import model from './model';
import { bi } from '../../utils/bi';
import { getBadgesLinks } from '../../api';
import preferredAppToKey from '../../utils/preferredAppToKey';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { t } = flowAPI.translations;
  $widget.onPropsChanged((oldProps, newProps) => {
    if (newProps.preferredApp) {
      setImagesAndLinks(
        newProps.preferredApp,
        newProps.iosOwnerPreferredApp,
        newProps.androidOwnerPreferredApp,
        newProps.ownerPreferredApp,
        newProps.metaSiteId || oldProps.metaSiteId,
      );
    }
  });

  async function setImagesAndLinks(
    preferredApp: string,
    iosOwnerPreferredApp: string,
    androidOwnerPreferredApp: string,
    ownerPreferredApp: string,
    metaSiteId: string,
  ) {
    const app = preferredAppToKey(preferredApp);
    const iosPreferred =
      iosOwnerPreferredApp || ownerPreferredApp ? app : 'spaces';
    const androidPreferred =
      androidOwnerPreferredApp || ownerPreferredApp ? app : 'spaces';

    $w('#imageX1').alt = t('download_my_app.widget.app_store.alt');
    $w('#imageX2').alt = t('download_my_app.widget.google_play.alt');
    // links
    $w('#imageX1').onClick(() => {
      bi(flowAPI).shareYourMobilePlaceClickOnButton('app store');
    });
    $w('#imageX2').onClick(() => {
      bi(flowAPI).shareYourMobilePlaceClickOnButton('google play');
    });
    if (app === 'branded') {
      const { appStoreLink, googlePlayLink } = await getBadgesLinks(
        flowAPI,
        metaSiteId,
      );
      $w('#imageX1').link = appStoreLink;
      $w('#imageX2').link = googlePlayLink;
    } else {
      $w('#imageX1').link = t(
        `download_my_app.widget.app_store.link.${iosPreferred}`,
      );
      $w('#imageX1').onClick(() => {
        bi(flowAPI).shareYourMobilePlaceClickOnButton('app store');
        return t(`download_my_app.widget.app_store.link.${app}`);
      });
      $w('#imageX2').link = t(
        `download_my_app.widget.google_play.link.${androidPreferred}`,
      );
    }
  }

  function getMobileOS(window) {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  function getBadgesDisplaySettings() {
    const deviceType = flowAPI.controllerConfig.wixCodeApi.window.formFactor;
    const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;
    const displayBadges = { appStore: true, googlePlay: true };

    if (viewMode === 'Site' && deviceType === 'Mobile') {
      const os = getMobileOS(flowAPI.controllerConfig.wixCodeApi.window);
      if (os === 'iOS') {
        displayBadges.googlePlay = false;
      } else if (os === 'Android') {
        displayBadges.appStore = false;
      }
    } else if (
      (viewMode === 'Editor' || viewMode === 'Preview') &&
      deviceType === 'Mobile'
    ) {
      displayBadges.googlePlay = false;
    }
    return displayBadges;
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { appStore, googlePlay } = getBadgesDisplaySettings();

      $w('#imageX1').show();
      $w('#imageX2').show();

      if (!googlePlay) {
        $w('#imageX2').hide();
      }
      if (!appStore) {
        $w('#imageX1').hide();
      }

      // const preferredApp = $widget.props.preferredApp;
      // if (preferredApp) {
      //   setImagesAndLinks(preferredApp);
      // }
    },
    exports: {},
  };
});
