import getCountriesCodes from '../../utils/getCountriesCodes';
import getCurrentCountryCode from '../../api/getCurrentCountryCode';
import validatePhoneNumber from '../../utils/validatePhoneNumber';
import model from './model';
import '../../utils/string.extensions';
import { bi } from '../../utils/bi';
import { sendSms } from '../../api';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { instance } = flowAPI.controllerConfig.appParams;
  const siteUrl = new URL(flowAPI.controllerConfig.wixCodeApi.location.baseUrl);

  $widget.onPropsChanged((oldProps, newProps) => {
    if (newProps.preferredApp && newProps.siteName && newProps.appUrl) {
      setButtonClick(newProps);
    }
  });

  function setButtonClick(props = $widget.props) {
    $w('#button1').onClick(() => {
      bi(flowAPI).shareYourMobilePlaceClickOnButton('send');

      // @ts-expect-error
      flowAPI.controllerConfig.wixCodeApi.authentication
        .openCaptchaChallenge()
        .then((token) => {
          // //@ts-ignore
          // $w('#input1').ariaAttributes.describedBy = '';
          $w('#smsMessages1').clearMessages();

          const countryCode = $w('#dropdown1').value;
          const phoneNumber = $w('#input1').value;

          if (!validatePhoneNumber(phoneNumber)) {
            // //@ts-ignore
            // $w('#input1').ariaAttributes.describedBy = 'multiStateBox1';
            $w('#smsMessages1').displayError(
              flowAPI.translations.t('download_my_app.widget.invalidPhone'),
            );
            bi(flowAPI).shareYourMobilePlaceError('invalid number');
            return;
          }
          debugger;
          const fullPhoneNumber = `${countryCode}${phoneNumber}`;
          sendSms(
            siteUrl.origin,
            fullPhoneNumber,
            token,
            instance,
            props.preferredApp,
            props.siteName.removeInvalidChars(),
            props.appUrl,
          )
            .then(() => {
              $w('#smsMessages1').displaySuccess(
                flowAPI.translations.t('download_my_app.widget.success'),
              );
            })
            .catch((err) => {
              console.log({ err });
              bi(flowAPI).shareYourMobilePlaceError('API');
              $w('#smsMessages1').displayServerError(
                flowAPI.translations.t('download_my_app.widget.serverError'),
              );
            });
        })
        .catch((err) => console.log('captcha error', err));
    });
  }

  function setTexts() {
    $w('#input1').label = flowAPI.translations.t(
      'download_my_app.widget.phoneNumber',
    );
    $w('#input1').placeholder = flowAPI.translations.t(
      'download_my_app.widget.phoneNumber.placholder',
    );
    $w('#dropdown1').label = flowAPI.translations.t(
      'download_my_app.widget.countryCode',
    );
    $w('#button1').label = flowAPI.translations.t(
      'download_my_app.widget.sendButton',
    );
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});

      $w('#smsMessages1').clearMessages();
      setTexts();

      $w('#dropdown1').options = getCountriesCodes();

      const code = await getCurrentCountryCode();
      $w('#dropdown1').placeholder = code;
      $w('#dropdown1').value = code;

      // setButtonClick();

    },
    exports: {},
  };
});
