import CountriesList from 'countries-list';

const getCountriesCodes = () =>
  Object.values(CountriesList.countries).flatMap(
    ({ phone }: { phone: string }) => {
      const codes = phone
        .split(',')
        .map((p) => ({ value: `+${p}`, label: `+${p}` }));
      return codes;
    },
  );

export default getCountriesCodes;
