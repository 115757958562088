import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export interface APIBadgesLinkResponse {
  appStoreLink?: string;
  googlePlayLink?: string;
}
export const getBadgesLinks = async (
  flowApi: PlatformControllerFlowAPI,
  metaSiteId: string,
) => {
  try {
    const { data } = await flowApi.httpClient.get<APIBadgesLinkResponse>(
      `/_api/branded-apps/v1/app/public-config`,
      {
        headers: {
          Authorization: flowApi.controllerConfig.appParams.instance,
        },
        // params: {
        //   ids: metaSiteId,
        // },
      },
    );
    const { t } = flowApi.translations;
    return {
      appStoreLink: t('download_my_app.widget.app_store.link.spaces'),
      googlePlayLink: t('download_my_app.widget.google_play.link.spaces'),
      ...data,
    };
  } catch (err) {
    return { appStoreLink: '', googlePlayLink: '' };
  }
};
