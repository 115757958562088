import model from './model';

const deviceContentElements: { id: string; opacity: number }[] = [
  { id: 'profile2', opacity: 1 },
  { id: 'profile1', opacity: 1 },
  { id: 'header', opacity: 1 },
  { id: 'members1', opacity: 1 },
  { id: 'members2', opacity: 0.6 },
  { id: 'members3', opacity: 0.2 },
  { id: 'section1row1', opacity: 0.15 },
  { id: 'section1row2', opacity: 0.15 },
  { id: 'section1row3', opacity: 0.15 },
  { id: 'section2row1', opacity: 0.15 },
  { id: 'section2row2', opacity: 0.15 },
  { id: 'section2row3', opacity: 0.15 },
];

export default model.createController(({ $w, $widget, flowAPI }) => {
  function setSiteName(props = $widget.props) {
    if ($w('#siteName').text !== props.siteName) {
      $w('#siteName').text = props.siteName;
    }
  }

  function setLogo(props = $widget.props) {
    const { showLogoInHeader, logoImageUrl } = props;

    if (showLogoInHeader === false) {
      $w('#logo').hide();
    } else if (showLogoInHeader === true) {
      $w('#logo').show();
      $w('#logo').src = logoImageUrl;
    }
  }

  function coverImage(props = $widget.props) {
    const { coverImageUrl } = props;
    if (coverImageUrl) {
      $w('#coverImage').src = coverImageUrl;
    }
  }

  function setColor(props = $widget.props) {
    const { primaryColor } = props;

    if (primaryColor) {
      $w('#inviteBtn').style.borderColor = primaryColor;
      $w('#inviteBtn').style.color = primaryColor;
      // $w('#bookNowBtn').style.backgroundColor = primaryColor;
      // $w('#bookNowBtn').style.borderColor = primaryColor;
      deviceContentElements.forEach(({ opacity, id }) => {
        let opHex = Math.round(opacity * 255).toString(16);
        if (opHex.length === 1) {
          opHex = '0' + opHex;
        }
        // @ts-expect-error
        const element = $w(`#${id}`) as any;
        const fullColor = primaryColor + opHex;

        if (element.style) {
          element.style.backgroundColor = fullColor;
        }
      });
    }
  }

  $widget.onPropsChanged((oldProps, newProps) => {
    setSiteName(newProps);
    setLogo(newProps);
    coverImage(newProps);
    setColor(newProps);
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      setSiteName();
      setLogo();
      coverImage();
      setColor();
    },
    exports: {},
  };
});
