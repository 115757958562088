import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  $widget.onPropsChanged((oldProps, newProps) => {
    $w('#phoneInput1').preferredApp = $widget.props.preferredApp;
    $w('#phoneInput1').appUrl = $widget.props.appUrl;
    $w('#phoneInput1').siteName = $widget.props.siteName;
    $w('#qrCodePlusBadges1').prefrerredApp = $widget.props.preferredApp;
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
