interface String {
  prefix(pre: string): string;
  removeInvalidChars(): string;
}

String.prototype.prefix = function (pre: string) {
  return pre + this;
};

String.prototype.removeInvalidChars = function () {
  return this.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  ).replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '$1');
};
