import { PreferredApp } from '../../types/types';
// import getPreferredApp from '../../utils/getPreferredApp';
// import preferredAppToKey from '../../utils/preferredAppToKey';
// import sendSms from '../../utils/sendSMS';
// import validatePhoneNumber from '../../utils/validatePhoneNumber';
import model from './model';

const inviteUrls: Record<PreferredApp, string> = {
  fit: 'https://s2jch.app.link/HLEdtdt7Neb',
  dine: 'https://1ov5b.app.link/mzhAyfJvefb',
  spaces: 'http://wix.to/YUCsB4o',
  branded: '',
};

export default model.createController(
  ({ $w, initState, $bind, $bindAll, $widget, flowAPI }) => {
    // const { state } = initState<{
    //   preferredApp: PreferredApp;
    //   prefix: string;
    //   phoneNumber: string;
    // }>({
    //   preferredApp: 'spaces',
    //   prefix: '',
    //   phoneNumber: '',
    // });

    // const { instance } = flowAPI.controllerConfig.appParams;

    // const t = (...keys) =>
    //   flowAPI.translations.t(
    //     ['download_my_app', 'widget', ...keys].filter((e) => e).join('.'),
    //   );

    return {
      pageReady: async () => {
        // $widget.fireEvent('widgetLoaded', {});

        // $w('#title').text = t('title');
        // $w('#description').text = t(
        //   'description',
        //   state.preferredApp !== 'fit' ? '' : state.preferredApp
        // );

        // $w('#appWrapper').changeState('emptyState');
        // getPreferredApp(instance).then((preferredApp) => {
        //   state.preferredApp = preferredAppToKey(preferredApp);

        //   $w('#appWrapper')
        //     .changeState('displayApp')
        //     .then(() => {
        //       $bind('#phoneNumber', {
        //         placeholder: () => t('phoneNumber'),
        //         value: () => state.phoneNumber,
        //         onChange: (e) => (state.phoneNumber = e.target.value),
        //       });

        //       $bind('#prefix', {
        //         placeholder: () => t('prefix'),
        //         value: () => state.prefix,
        //         onChange: (e) => (state.prefix = e.target.value),
        //       });
        //     });

        //   $bind('#sendBtn', {
        //     label: () => t('send'),
        //     disabled: () => !state.prefix || !state.phoneNumber,
        //     onClick: () => {
        //       // send SMS!!!
        //       if (!validatePhoneNumber(state.phoneNumber)) {
        //         console.log('invalid number');
        //       } else {
        //         console.log('sending sms');
        //         const message = t('sms') + inviteUrls[state.preferredApp];
        //         sendSms(state.prefix, state.phoneNumber, message, instance)
        //           .then((data) => console.log({ data }))
        //           .catch((err) => console.log({ err }));
        //       }
        //     },
        //   });
        // });
      },
      exports: {},
    };
  },
);
