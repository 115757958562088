import { getSiteName } from '../../api';
import { generateQRCode } from '../../api/utils/qr-code';
import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  const { t } = flowAPI.translations;

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      $w('#imageX1').alt = t('download_my_app.widget.qr.alt"');

      const { url } = await getSiteName(flowAPI);

      const qrCode = generateQRCode(url);

      if (qrCode) {
        $w('#imageX1').src = qrCode;
      }
    },
    exports: {},
  };
});
