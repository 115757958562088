import model from './model';

export default model.createController(({ $w, $widget, flowAPI }) => {
  $widget.onPropsChanged((oldProps, newProps) => {
    $w('#badges1').preferredApp = $widget.props.prefrerredApp;
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});
