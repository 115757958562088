import { externalPost } from '../utils/api';

const appNames = {
  fit: 'Fit',
  dine: 'Dine',
};
const defaultAppName = 'Spaces';

export const sendSms = (
  origin: string,
  phoneNumber: string,
  recaptchaToken: unknown,
  instance: string,
  preferredApp: string,
  placeName: string,
  joinUrl: string,
) => {
  const headers = {
    Authorization: instance,
    'Content-Type': 'application/json',
  };
  const isVeloCaptcha = true;
  const parameters: any = {
    placeName,
    joinUrl,
  };
  if (preferredApp !== 'branded') {
    parameters.appName = appNames[preferredApp] || defaultAppName;
  }

  const data = {
    template: preferredApp === 'branded' ? 'labs-join' : 'labs-stay-updated',
    recaptchaToken,
    origin: 'labs',
    isVeloCaptcha,
    parameters,
  };

  return externalPost(
    `${origin}/_api/clubs-invites/sms/${phoneNumber.removeInvalidChars()}`,
    headers,
    data,
  );
}
